import styled from "styled-components";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";

export const CardTitle = styled(Card.Title)`
  font-size: 1.125rem;
`;
export const TextPrimaryCardTitle = styled(CardTitle)`
  --bs-text-opacity: 1;
  color: #696cff !important;
  font-weight: 500;
  line-height: 1.1;
`;

export const ButtonLink = styled(Link)`
  color: #696cff;
  border-color: #696cff;
  background: transparent;
  cursor: pointer;
  padding: 0.25rem 0.6875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.53;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #696cff;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #696cff;
    outline: none;
    box-shadow: none;
  }
`;

export const DashboardCard = styled(Card)`
  background-clip: padding-box;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  border: 0 solid #d9dee3;
  border-radius: 0.5rem;
`;

export const RowBordered = styled(Row)`
  overflow: hidden;
  &.col,
  [class^="col-"],
  [class*=" col-"],
  [class^="col "],
  [class*=" col "],
  [class$=" col"],
  [class="col"] {
    position: relative;
    padding-top: 1px;
  }
  &.col::before,
  [class^="col-"]::before,
  [class*=" col-"]::before,
  [class^="col "]::before,
  [class*=" col "]::before,
  [class$=" col"]::before,
  [class="col"]::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    display: block;
    height: 0;
    border-top: 1px solid #d9dee3;
  }
  &.col::after,
  [class^="col-"]::after,
  [class*=" col-"]::after,
  [class^="col "]::after,
  [class*=" col "]::after,
  [class$=" col"]::after,
  [class="col"]::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    display: block;
    width: 0;
    border-left: 1px solid #d9dee3;
  }
`;
