import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { useCompany } from "../../provider/companyProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Speedometer2,
  Buildings,
  Sliders2,
  ArrowLeftRight,
  BoxArrowRight,
  ArrowLeft,
  Building,
  Shop,
  Hammer,
  Receipt,
  Postcard,
  Send,
  Lightning,
  Calendar2Check,
  Database,
  Map,
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
} from "react-bootstrap-icons";
import { useAuth } from "../../provider/authProvider";
import { useEffect, useState } from "react";
import useFetchData from "../../api/useFetchData";
import ImageReader from "./ImageReader";
import SidebarNavLink from "./SidebarNavLink";
import clsx from "clsx";
import { KAppBrand, KSideBar } from "./StyledComponents";
import truncateString from "../../js-functions/truncateString";
import User from "../Views/User";

const Sidebar = () => {
  const { t } = useTranslation();
  const { companyName, companyLogo, companyId, setCompanyId } = useCompany();
  const { user, profilePicture } = useAuth();
  const [settingsView, setSettingsView_] = useState(
    sessionStorage.getItem("settings_view"),
  );

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const location = useLocation();

  const companyUrl = `/api/v1/companies/${companyId}`;
  const [company, refreshCompany] = useFetchData(companyUrl);

  const userUrl = `/api/v1/users/${user.id}`;
  const [u, refreshUser] = useFetchData(userUrl);

  useEffect(() => {
    refreshUser({});
  }, [profilePicture, refreshUser]);

  useEffect(() => {
    refreshCompany({});
  }, [companyLogo, refreshCompany]);

  const setSettingsView = (value) => {
    setSettingsView_(value);
    sessionStorage.setItem("settings_view", value);
  };

  const navigate = useNavigate();
  const sidebarWidth = sidebarOpen ? "250px" : "57px";

  const links = [
    {
      name: t("Dashboard.text"),
      eventKey: "",
      icon: <Speedometer2 className="me-2" />,
    },
    {
      name: t("Task.text", { count: 100 }),
      eventKey: "reminders",
      icon: <Calendar2Check className="me-2" />,
    },
    {
      name: t("Account.text", { count: 100 }),
      eventKey: "accounts",
      icon: <Buildings className="me-2" />,
    },
    {
      name: t("Dispute.text", { count: 100 }),
      eventKey: "disputes",
      icon: <Hammer className="me-2" />,
    },
    {
      name: t("Action.text", { count: 100 }),
      eventKey: "actions",
      icon: <Lightning className="me-2" />,
    },
    {
      name: t("Campaign.text", { count: 100 }),
      eventKey: "campaigns",
      icon: <Send className="me-2" />,
    },
  ];

  const settings_links = [
    {
      name: t("Company.text"),
      eventKey: "company",
      icon: <Building className="me-2" />,
    },
    {
      name: t("Subsidiaries.text"),
      eventKey: "subsidiaries",
      icon: <Shop className="me-2" />,
    },
    {
      name: t("DisputesStatus.text"),
      eventKey: "disputes_status",
      icon: <Hammer className="me-2" />,
    },
    {
      name: t("InvoiceStatus.text"),
      eventKey: "invoices_status",
      icon: <Receipt className="me-2" />,
    },
    {
      name: t("Workflows.text"),
      eventKey: "workflows",
      icon: <Map className="me-2" />,
    },
    {
      name: t("MessageTemplate.text", { count: 100 }),
      eventKey: "message_templates",
      icon: <Postcard className="me-2" />,
    },
    {
      name: t("Data.text"),
      eventKey: "data",
      icon: <Database className="me-2" />,
    },
  ];

  return (
    <KSideBar style={{ width: sidebarWidth }}>
      {sidebarOpen ? (
        <ArrowLeftCircleFill
          className="align-self-sm-end mt-4 me-2"
          size={24}
          style={{ cursor: "pointer", color: "#696cff" }}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
      ) : (
        <ArrowRightCircleFill
          size={24}
          className="align-self-center mt-4 mb-4"
          style={{ cursor: "pointer", color: "#696cff" }}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />
      )}

      <div
        className={clsx(sidebarOpen ? "ms-2" : "ms-2", "mt-2")}
        style={{ display: "flex" }}
      >
        <ImageReader
          fileUrl={company.logo_url}
          style={{
            height: "40px",
            width: "40px",
            marginBottom: !sidebarOpen && "30px",
            backgroundColor: "white",
          }}
          imageType={"rounded-circle me-2"}
        />
        {sidebarOpen && (
          <KAppBrand className="ms-2">
            {truncateString(companyName, 10)}
          </KAppBrand>
        )}
      </div>

      <Nav
        variant="pills"
        className="flex-column mb-auto"
        defaultActiveKey={sessionStorage.getItem("selected_menu")}
        onSelect={(selectedKey) => {
          sessionStorage.setItem("selected_menu", selectedKey);
          navigate(`/${selectedKey}`, { replace: true });
        }}
      >
        <Nav.Item className="ms-1 me-1">
          {settingsView === "true" ? (
            <>
              <SidebarNavLink onClick={() => setSettingsView("false")}>
                <p style={{ fontSize: "17px", fontWeight: "500" }}>
                  <ArrowLeft className="me-2" />
                  {sidebarOpen && t("Settings.text")}
                </p>
              </SidebarNavLink>
              {settings_links.map((link) => {
                return (
                  <SidebarNavLink
                    key={link.name}
                    title={link.name}
                    style={{ width: !sidebarOpen && "42px" }}
                    eventKey={link.eventKey}
                    active={location.pathname === `/${link.eventKey}`}
                  >
                    {link.icon}
                    {sidebarOpen && link.name}
                  </SidebarNavLink>
                );
              })}
            </>
          ) : (
            <>
              {links.map((link) => {
                return (
                  <SidebarNavLink
                    key={link.name}
                    title={link.name}
                    eventKey={link.eventKey}
                    style={{ width: !sidebarOpen && "42px" }}
                    active={location.pathname === `/${link.eventKey}`}
                  >
                    {link.icon}
                    {sidebarOpen && link.name}
                  </SidebarNavLink>
                );
              })}
              <SidebarNavLink onClick={() => setSettingsView("true")}>
                <Sliders2 className="me-2" title={t("Settings.text")} />
                {sidebarOpen && t("Settings.text")}
              </SidebarNavLink>
            </>
          )}
        </Nav.Item>
      </Nav>
      {sidebarOpen && (
        <div className="mb-2">
          <hr style={{ color: "#233446" }} className="ms-3 me-3" />
          <Dropdown>
            <Dropdown.Toggle
              variant="nav-item"
              style={{ borderColor: "transparent", color: "#233446" }}
            >
              <ImageReader
                fileUrl={u.picture_url}
                style={{
                  height: "32px",
                  width: "32px",
                  border: "solid white 2px",
                  padding: "3px",
                  backgroundColor: "white",
                }}
                imageType={"rounded-circle me-2"}
              />

              <strong>{user.pseudo || user.display_name}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <User />
              <hr />
              <Dropdown.Item
                onClick={() => {
                  setCompanyId("");
                }}
              >
                <ArrowLeftRight className="me-2" />
                {t("SwitchCompany.text")}
              </Dropdown.Item>
              <Dropdown.Item href="/logout">
                <BoxArrowRight className="me-2" />
                {t("SignOut.text")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </KSideBar>
  );
};

export default Sidebar;
