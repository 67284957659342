import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useAuth } from "../provider/authProvider";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_API_URL;

const useFetchData = (url) => {
  const [data, setData] = useState([]);
  const { token, setToken } = useAuth();
  const [shouldRefetch, refetch] = useState({});

  const config = useMemo(() => {
    return {
      baseURL: baseUrl,
      timeout: 10000,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
  }, [token]);

  useEffect(() => {
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.pagination) {
          setData(response.data.data);
        } else {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setToken("");
          } else {
            toast.error(
              `${error.response.status} - ${error.response.statusText}`
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("No Server Response");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(error.message);
        }
      });
  }, [url, shouldRefetch, config, setToken]);
  return [data, refetch];
};

export default useFetchData;
