import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  KAppBrand,
  Wrapper,
  XXLContainer,
} from "../../CustomTags/StyledComponents";
import { useTranslation } from "react-i18next";
import ImageReader from "../../CustomTags/ImageReader";
import { Col, Row } from "react-bootstrap";
import AccountInfo from "./AccountInfo";
import Outstanding from "../Dashboard/Outstanding";
import InvoicesList from "./InvoicesList";
import api from "../../../api/api";

const Portal = () => {
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const [accountInfo, setAccountInfo] = useState({});

  const outstandingData = {
    not_dues: accountInfo.not_due_amount,
    dues: accountInfo.due_amount,
  };

  useEffect(() => {
    const config = {
      baseURL: baseUrl,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .get(`/api/v1/show_portal?portal_token=${id}`, config)
      .then((response) => {
        setAccountInfo(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
          toast.error(
            `${error.response.status} - ${error.response.statusText}`,
          );
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("No Server Response");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(error.message);
        }
      });
  }, [id, baseUrl]);

  const getCheckoutUrl = (invoiceIds) => {
    const config = {
      method: "post",
      url: `${baseUrl}/api/v1/companies/${accountInfo.company.id}/customers/${accountInfo.id}/create_checkout_session`,
      data: JSON.stringify({
        payment: { invoice_ids: invoiceIds },
      }),
    };
    api
      .request(config)
      .then(function (response) {
        window.location.replace(response.data.url);
      })
      .catch(function (error) {});
  };

  return (
    <Wrapper>
      <ToastContainer />
      <XXLContainer>
        <div className="ms-2 mt-2" style={{ display: "flex" }}>
          <ImageReader
            fileUrl={accountInfo?.company?.logo_url}
            style={{
              height: "40px",
              width: "40px",
              backgroundColor: "white",
            }}
            imageType={"rounded-circle me-2"}
          />
          <KAppBrand className="ms-2">{accountInfo.company?.name}</KAppBrand>
        </div>
        <p className="text-muted ms-5" style={{ textTransform: "uppercase" }}>
          {t("Customer Portal")}
        </p>

        <Row className="ms-2">
          <Col md={4} className="mb-4">
            <AccountInfo account={accountInfo} />
          </Col>
          <Col className="mb-4">
            <Outstanding data={outstandingData} />
          </Col>
        </Row>
        <Row className="ms-2">
          <Col className="mb-4">
            <InvoicesList
              invoices={accountInfo.invoices}
              getCheckoutUrl={getCheckoutUrl}
              stripeEnabled={
                accountInfo.company && accountInfo.company["stripe_enabled?"]
              }
            />
          </Col>
        </Row>
      </XXLContainer>
    </Wrapper>
  );
};
export default Portal;
