import { useEffect, useState } from "react";
import logo from "../../logo.png";
import { Image } from "react-bootstrap";

const ImageReader = ({ localFile, fileUrl, style, imageType }) => {
  const [fileDataURL, setFileDataURL] = useState(null);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (localFile) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(localFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [localFile]);

  useEffect(() => {
    setFileDataURL(fileUrl);
  }, [fileUrl]);

  return (
    <Image
      src={fileDataURL ? fileDataURL : logo}
      alt="profile"
      style={style}
      className={imageType}
    />
  );
};

export default ImageReader;
