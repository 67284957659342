// import api from "../api/api";

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(secureLocalStorage.getItem("token"));
  const [user, setUser_] = useState(secureLocalStorage.getItem("user"));
  const [profilePicture, setProfilePicture_] = useState("");

  // Profile Picture
  const setProfilePicture = (newProfilePicture) => {
    setProfilePicture_(newProfilePicture);
  };

  // Function to set the authentication token
  const setToken = (newToken) => {
    setToken_(newToken);
  };

  const setUser = (newUser) => {
    setUser_(newUser);
  };

  useEffect(() => {
    if (token && user) {
      // api.defaults.headers.common["Authorization"] = "Bearer " + token;
      secureLocalStorage.setItem("token", token);
      secureLocalStorage.setItem("user", user);
    } else {
      // delete api.defaults.headers.common["Authorization"];
      secureLocalStorage.removeItem("token");
      secureLocalStorage.removeItem("user");
    }
  }, [token, user]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      user,
      setUser,
      profilePicture,
      setProfilePicture,
    }),
    [token, user, profilePicture]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
