// import logo from './logo.svg';
import "./App.css";
import AuthProvider from "./provider/authProvider";
import CompanyProvider from "./provider/companyProvider";
import Routes from "./routes";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <CompanyProvider>
          <Routes />
        </CompanyProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
