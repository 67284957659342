import Nav from "react-bootstrap/Nav";

function SidebarNavLink({
  children,
  eventKey,
  onClick,
  className,
  style,
  active,
}) {
  return (
    <>
      <style type="text/css">
        {`
          .nav-pills .nav-link {
            font-size: 1rem;
            font-weight: 500;
            color: #697a8d;
          }

          .nav-pills .nav-link.active{
            background-color: #e7e7ff;
            color: #696cff;
          }

          .nav-pills .nav-link.active > svg{
            color: #696cff;
          }
        `}
      </style>

      <Nav.Link
        variant="sidebar"
        eventKey={eventKey}
        className={className}
        style={style}
        onClick={onClick}
        active={active}
      >
        {children}
      </Nav.Link>
    </>
  );
}

export default SidebarNavLink;
