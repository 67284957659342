import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Person } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import UserForm from "./UserForm";

const User = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      {showForm && <UserForm showForm={showForm} setShowForm={setShowForm} />}
      <Dropdown.Item onClick={() => setShowForm(true)}>
        <Person className="me-2" />
        {t("Profile.text")}
      </Dropdown.Item>
    </>
  );
};

export default User;
