import { lazy, Suspense, useEffect, useReducer } from "react";
import { useAuth } from "../../provider/authProvider";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "react-phone-number-input/style.css";
import api from "../../api/api";
import { toast } from "react-toastify";
import useFetchData from "../../api/useFetchData";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { object, string, mixed } from "yup";

import UserPicture from "./UserPicture";
import PhoneInput from "react-phone-number-input";
const MessageTemplateEditor = lazy(() =>
  import("./MessagesTemplates/MessageTemplateEditor"),
);

function reducerInitialState(state, action) {
  switch (action.type) {
    case "updateUser":
      const user = action.value;
      return {
        display_name: user.display_name ? user.display_name : "",
        pseudo: user.pseudo ? user.pseudo : "",
        phone_number: user.phone_number ? user.phone_number : "",
        signature: user.signature_html ? user.signature_html : "",
      };
    default:
      throw new Error();
  }
}

const UserForm = ({ showForm, setShowForm }) => {
  const { t } = useTranslation();
  const { token, setToken, user, setUser, setProfilePicture } = useAuth();
  const userUrl = `/api/v1/users/${user.id}`;
  const [u] = useFetchData(userUrl);

  const initialState = {
    display_name: "",
    pseudo: "",
    phone_number: "",
    signature: null,
    picture: "",
    password: "",
  };

  const [initialValues, updateInitialValues] = useReducer(
    reducerInitialState,
    initialState,
  );

  const handleClose = () => {
    setShowForm(false);
  };

  const schema = object({
    display_name: string()
      .min(1, t("ContentMin.text"))
      .max(100, t("ContentMax.text"))
      .required(t("Required.text")),
    pseudo: string()
      .min(1, t("ContentMin.text"))
      .max(10, t("ContentMax.text"))
      .required(t("Required.text")),
    phone_number: string(),
    signature: string(),
    picture: mixed(),
    password: string()
      .min(6, t("InvalidPassword.text"))
      .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/, {
        message: t("InvalidPassword.text"),
      }),
  });

  useEffect(() => {
    if (u.display_name) {
      updateInitialValues({
        type: "updateUser",
        value: u,
      });
    }
  }, [u]);

  const saveUser = (formData) => {
    const config = {
      method: "put",
      url: `/api/v1/users/${user.id}`,
      data: { user: formData },
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    };

    api
      .request(config)
      .then(function ({ data }) {
        toast.success("🦄 Wow so easy!");

        setUser({
          ...user,
          display_name: data.display_name,
          phone_number: data.phone_number,
        });
        setProfilePicture(data.picture_url);

        handleClose();
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setToken("");
          } else {
            toast.error(
              `${error.response.status} - ${error.response.statusText}`,
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("No Server Response");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(error.message);
        }
      });
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={saveUser}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          setValues,
          values,
          touched,
          errors,
        }) => (
          <Modal size="lg" show={showForm} onHide={handleClose}>
            <Form noValidate onSubmit={handleSubmit} className="mb-3">
              <Modal.Header closeButton>
                <Modal.Title>Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col style={{ textAlign: "center" }}>
                    <UserPicture
                      pictureUrl={u.picture_url}
                      formData={values}
                      setFormData={setValues}
                    />
                    <ToggleButtonGroup
                      className="mt-5"
                      type="radio"
                      name="languages"
                      defaultValue={i18n.language}
                      onChange={(val) => i18n.changeLanguage(val)}
                    >
                      <ToggleButton id="tbg-radio-1" value={"fr"}>
                        Français
                      </ToggleButton>
                      <ToggleButton id="tbg-radio-2" value={"en"}>
                        English
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                  <Col md={7}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Name.text")}</Form.Label>
                      <Form.Control
                        name="display_name"
                        value={values.display_name}
                        onChange={handleChange}
                        isValid={touched.display_name && !errors.display_name}
                        isInvalid={!!errors.display_name}
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.display_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Pseudo.text")}</Form.Label>
                      <Form.Control
                        name="pseudo"
                        value={values.pseudo}
                        onChange={handleChange}
                        isValid={touched.pseudo && !errors.pseudo}
                        isInvalid={!!errors.pseudo}
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.pseudo}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        placeholder={t("Password.text")}
                        onChange={handleChange}
                        isValid={touched.email && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* Password */}
                    <Form.Group className="mb-3">
                      <Form.Label>{t("PhoneNumber.text")}</Form.Label>
                      <PhoneInput
                        style={{
                          borderColor: "#dee2e6",
                        }}
                        value={values.phone_number}
                        onChange={(e) =>
                          setValues({ ...values, phone_number: e })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Signature */}
                  <Form.Group className="mb-3 mt-5">
                    <Form.Label>{t("Signature.text")}</Form.Label>
                    <Suspense
                      fallback={
                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                          <Spinner
                            animation="border"
                            role="status"
                            variant="warning"
                          />
                        </div>
                      }
                    >
                      {values.signature !== null && (
                        <MessageTemplateEditor
                          content={values.signature}
                          handleContentChange={(c) => {
                            const html_content = c.replace(/"/g, "'");
                            setValues({ ...values, signature: html_content });
                          }}
                        />
                      )}
                    </Suspense>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {t("Close.text")}
                </Button>
                <Button type="submit" variant="primary">
                  {t("Save.text")}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default UserForm;
