import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useCompany } from "../provider/companyProvider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../components/CustomTags/SideBar";
import CompanySelector from "../components/Views/Company/CompanySelector";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ProtectedRoute = () => {
  const { token } = useAuth();
  const { companyId } = useCompany();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      {companyId ? (
        <Row style={{ height: "100%" }}>
          <Col
            className="pe-0"
            md="auto"
            id="side-bar-col"
            style={{
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Sidebar />
          </Col>
          <Col
            className="ps-0"
            style={{
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Outlet />
          </Col>
        </Row>
      ) : (
        <CompanySelector />
      )}
      <ToastContainer />
    </>
  );
};
