import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const UnpaidRate = ({ data }) => {
  const { t } = useTranslation();
  const [unpaidRate, setUnpaidRate] = useState(5);

  const cardColor = "#f1f5f7";
  const headingColor = "#6c757d";
  const lineColor = "#696cff";

  useEffect(() => {
    if (data) {
      setUnpaidRate(
        (parseInt(data.dues) * 100) /
          (parseInt(data.dues) + parseInt(data.not_dues)),
      );
    }
  }, [data]);

  const options = {
    series: [78],
    labels: [t("Late")],
    plotOptions: {
      radialBar: {
        size: 150,
        offsetY: 15,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: "55%",
        },
        track: {
          background: cardColor,
          strokeWidth: "100%",
        },
        dataLabels: {
          name: {
            offsetY: 15,
            color: headingColor,
            fontSize: "15px",
            fontWeight: "600",
            fontFamily: "Public Sans",
          },
          value: {
            offsetY: -25,
            color: headingColor,
            fontSize: "22px",
            fontWeight: "500",
            fontFamily: "Public Sans",
          },
        },
      },
    },
    colors: [lineColor],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.5,
        gradientToColors: [lineColor],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.6,
        stops: [30, 50, 100],
      },
    },
    stroke: {
      dashArray: 5,
    },
    grid: {
      padding: {
        top: 0,
        bottom: 20,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  };
  return (
    <>
      {unpaidRate > 0 && (
        <center>
          <Chart
            options={options}
            height={180}
            width={180}
            series={[parseInt(unpaidRate)]}
            type="radialBar"
          />
        </center>
      )}
      <p className="text-center" style={{ fontSize: "12px" }}>{`${parseInt(
        100 - unpaidRate,
      )}% ${t("not_past_due")}`}</p>
    </>
  );
};
export default UnpaidRate;
