import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
