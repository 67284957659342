import { useEffect, useState } from "react";
import { useAuth } from "../../../provider/authProvider";
import { useCompany } from "../../../provider/companyProvider";

import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Search } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import api from "../../../api/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CompanySelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, setToken } = useAuth();
  const { setCompanyId, setCompanyName } = useCompany();
  const [companies, setCompanies] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Fetch companies on load
  useEffect(() => {
    if (token) {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      api
        .get("/api/v1/companies", config)
        .then((response) => {
          setCompanies(response.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setToken("");
            } else {
              toast.error(
                `${error.response.status} - ${error.response.statusText}`
              );
            }
          } else if (error.request) {
            // The request was made but no response was received
            toast.error("No Server Response");
            setToken("");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast.error(error.message);
          }
        });
    }
  }, [token, setToken]);

  // Filter
  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  useEffect(() => {
    if (searchString !== "") {
      setSearchResults(
        companies.filter((company) => {
          return Object.values(company)
            .join(" ")
            .toLowerCase()
            .includes(searchString.toLowerCase());
        })
      );
    } else {
      setSearchResults(companies);
    }
  }, [searchString, companies]);

  // Select Company
  const selectCompany = (companyId, companyName) => {
    setCompanyId(companyId);
    localStorage.setItem("companyId", companyId);
    setCompanyName(companyName);
    localStorage.setItem("companyName", companyName);
    navigate("/", { replace: true });
  };

  return (
    <div
      className="border d-flex align-items-center justify-content-center"
      style={{ height: "100%" }}
    >
      {companies.length > 0 ? (
        <div>
          <div style={{ width: "18rem" }}>
            <InputGroup className="mb-3">
              <Form.Control
                className="company-selector"
                placeholder={t("Search.text") + "..."}
                style={{
                  borderTop: "transparent",
                  borderLeft: "transparent",
                  borderRight: "transparent",
                  borderRadius: "0",
                }}
                value={searchString}
                onChange={handleSearch}
              />
              <InputGroup.Text
                style={{
                  border: "transparent",
                  backgroundColor: "transparent",
                }}
              >
                <Search />
              </InputGroup.Text>
            </InputGroup>
            <ListGroup>
              {searchResults.map(({ id, name }) => {
                return (
                  <ListGroup.Item
                    action
                    key={id}
                    onClick={() => selectCompany(id, name)}
                  >
                    {name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
        </div>
      ) : (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

export default CompanySelector;
