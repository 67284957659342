import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";

export const Wrapper = styled.section`
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f9;
  min-height: 100vh;
`;

export const XXLContainer = styled.div`
  padding-bottom: 1.625rem !important;
  padding-top: 1.625rem !important;
  min-height: 1px;
  flex-grow: 1 !important;
  margin-right: 20px;
  margin-left: 20px;
  max-width: 100%;
`;

export const KCard = styled(Card)`
  background-clip: padding-box;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  border: 0 solid #d9dee3;
  border-radius: 0.5rem;
  padding: 15px;
`;

export const KTable = styled(Table)`
  --bs-table-color: #566a7f !important;
  border-collapse: separate;
  border-spacing: 0 5px;
`;

export const KDangerButton = styled(Button)`
  color: #fff;
  background-color: #ff3e1d;
  border-color: #ff3e1d;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(133, 146, 163, 0.4);
  &:hover {
    color: #fff;
    background-color: #e6381a;
    border-color: #e6381a;
    transform: translateY(-1px);
  }
`;

export const KSecondaryButton = styled(Button)`
  color: #fff;
  background-color: #8592a3;
  border-color: #8592a3;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(133, 146, 163, 0.4);
  &:hover {
    color: #fff;
    background-color: #788393;
    border-color: #788393;
    transform: translateY(-1px);
  }
`;

export const KPrimaryButton = styled(Button)`
  color: #fff;
  background-color: #696cff;
  border-color: #696cff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);
  &:hover {
    color: #fff;
    background-color: #5f61e6;
    border-color: #5f61e6;
    transform: translateY(-1px);
  }
`;

export const KButton = styled.button`
  color: #696cff;
  border-color: #696cff;
  background: transparent;
  padding: 0.25rem 0.6875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.53;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #696cff;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #696cff;
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    color: rgba(67, 89, 113, 0.2);
    border: 1px solid rgba(67, 89, 113, 0.2);
    background: transparent;
  }
`;

export const KSelect = styled(Form.Select)`
  color: #696cff;
  border-color: #696cff;
  /* select arrow color use the stroke parma of svg to change it (!url encoded) */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23696cff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  font-size: 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #696cff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    outline: none;
    box-shadow: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    color: rgba(67, 89, 113, 0.7);
    border-color: rgba(67, 89, 113, 0.7);
    background: transparent;
  }
`;

export const KFormControl = styled(Form.Control)`
  font-size: 0.75rem;
  border-radius: 0.25rem;
  appearance: none; /* Define the standard property 'appearance' for compatibility */
  -moz-appearance: textfield;
  &:focus {
    border-color: #696cff;
    background-color: #ffffff;
    outline: none;
    box-shadow: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const KFormLabel = styled(Form.Label)`
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
  font-weight: 500;
  line-height: 1.53;
  color: #566a7f;
`;

export const KTitle = styled.p`
  --bs-text-opacity: 1;
  color: #696cff !important;
  font-weight: 500;
  line-height: 1.1;
`;

export const KBadgeBasic = styled(Badge)`
  background-color: #696cff !important;
`;

export const KBadgeLabel = styled(Badge)`
  background-color: #e7e7ff !important;
  color: #696cff !important;
`;

export const KBadgeAlert = styled(Badge)`
  background-color: #ffe0db !important;
  color: #ff3e1d !important;
`;

export const KBadgeSuccess = styled(Badge)`
  background-color: #e8fadf !important;
  color: #71dd37 !important;
`;

export const KSideBar = styled.div`
  height: 100%;
  transition: width 0.5s;
  background-color: #fff !important;
  flex-direction: column !important;
  display: flex !important;
  box-shadow: 0 0 0 1px #d9dee3 !important;
  color: #697a8d;
`;

export const KAppBrand = styled.p`
  font-size: "18px";
  color: "#233446";
  font-weight: 700 !important;
  font-size: 1.75rem;
`;

export const KNavLink = styled(Nav.Link)`
  color: #566a7f;
  &.active {
    background-color: #696cff !important;
    color: #fff !important;
  }
  &:hover {
    background-color: rgba(105, 108, 255, 0.16) !important;
    color: #5f61e6 !important;
  }
`;

export const KDropdownToggle = styled(Dropdown.Toggle)`
  background-color: #696cff !important;
  color: #fff !important;
`;
