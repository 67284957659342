import { GeoAlt } from "react-bootstrap-icons";
import { KCard } from "../../CustomTags/StyledComponents";
import { useTranslation } from "react-i18next";

const AccountInfo = ({ account }) => {
  const { t } = useTranslation();
  return (
    <div>
      <KCard style={{ height: "240px" }}>
        <KCard.Body>
          <KCard.Title className="mb-3">{account.name}</KCard.Title>
          <KCard.Subtitle className="text-muted mb-4">
            {account.ref_id && (
              <>
                {t("RefId.text")}: {account.ref_id}
              </>
            )}
          </KCard.Subtitle>
          <div
            className="container"
            style={{
              display: "flex",
              padding: "0px",
            }}
          >
            {(account.addr1 || account.postal_code || account.city) && (
              <GeoAlt color="red" />
            )}
            <div className="ms-2" style={{ fontSize: "12px", color: "gray" }}>
              {account.addr1 && (
                <p style={{ marginBottom: "0px" }}>{account.addr1}</p>
              )}
              {account.addr2 && (
                <p style={{ marginBottom: "0px" }}>{account.addr2}</p>
              )}
              {account.postal_code && account.city && (
                <p style={{ marginBottom: "0px" }}>
                  {account.postal_code} {account.city} {account.country}
                </p>
              )}
            </div>
          </div>
        </KCard.Body>
      </KCard>
    </div>
  );
};

export default AccountInfo;
