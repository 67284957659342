import { createContext, useContext, useEffect, useMemo, useState } from "react";

const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {
  const [companyId, setCompanyId_] = useState(
    localStorage.getItem("companyId")
  );
  const [companyName, setCompanyName_] = useState(
    localStorage.getItem("companyName")
  );
  const [companyLogo, setCompanyLogo_] = useState("");

  const setCompanyId = (newCompanyId) => {
    setCompanyId_(newCompanyId);
  };

  const setCompanyName = (newCompanyName) => {
    setCompanyName_(newCompanyName);
  };

  // Company Logo
  const setCompanyLogo = (newCompanyLogo) => {
    setCompanyLogo_(newCompanyLogo);
  };

  useEffect(() => {
    if (companyId) {
      localStorage.setItem("companyId", companyId);
    } else {
      localStorage.removeItem("companyId");
    }
  }, [companyId]);

  useEffect(() => {
    if (companyName) {
      localStorage.setItem("companyName", companyName);
    } else {
      localStorage.removeItem("companyName");
    }
  }, [companyName]);

  const contextValue = useMemo(
    () => ({
      companyId,
      setCompanyId,
      companyName,
      setCompanyName,
      companyLogo,
      setCompanyLogo,
    }),
    [companyId, companyName, companyLogo]
  );

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  return useContext(CompanyContext);
};

export default CompanyProvider;
