import { useTranslation } from "react-i18next";

const InvoicesListColumns = () => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        <th></th>
        <th style={{ textAlign: "left" }}>{t("Number.text")}</th>
        <th style={{ textAlign: "center" }}>{t("Order.text")}</th>
        <th style={{ textAlign: "center" }}>{t("Date.text")}</th>
        <th style={{ textAlign: "center" }}>{t("DueDate.text")}</th>
        <th style={{ textAlign: "center" }}>{t("Amount")}</th>
        <th style={{ textAlign: "center" }}>{t("Balance")}</th>
        <th style={{ textAlign: "center" }}>{t("ProcessingFees.text")}</th>
        <th style={{ textAlign: "center" }}>{t("Delay.text")}</th>
        <th style={{ textAlign: "center" }}>{t("Status.text")}</th>
        <th colSpan={2}></th>
      </tr>
    </thead>
  );
};
export default InvoicesListColumns;
