const formatAmount = (amount, rounded = false) => {
  const browserLanguage = navigator.language;
  const formattedAmount = new Intl.NumberFormat(browserLanguage, {
    style: "currency",
    currency: "EUR",
    rounded: rounded,
    maximumFractionDigits: rounded ? 0 : 2,
  }).format(amount);
  return formattedAmount;
};

export default formatAmount;
