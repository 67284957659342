import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import { Suspense, lazy } from "react";
import Portal from "../components/Views/Portal/Portal";

const Signin = lazy(() => import("../components/Views/Signin"));
const Logout = lazy(() => import("../components/Views/Logout"));

const Dashboard = lazy(() => import("../components/Views/Dashboard/Dashboard"));
const Accounts = lazy(() => import("../components/Views/Account/Accounts"));
const AccountContainer = lazy(() =>
  import("../components/Views/Account/AccountContainer"),
);
const RemindersContainer = lazy(() =>
  import("../components/Views/Reminder/RemindersContainer"),
);
const DisputesContainer = lazy(() =>
  import("../components/Views/Dispute/DisputesContainer"),
);
const ActionsContainer = lazy(() =>
  import("../components/Views/Action/ActionsContainer"),
);
const Campaigns = lazy(() => import("../components/Views/Campaign/Campaigns"));
const CampaignContainer = lazy(() =>
  import("../components/Views/Campaign/CampaignContainer"),
);

const FileImportLogs = lazy(() =>
  import("../components/Views/FileImport/FileImportLogs"),
);

const ImportData = lazy(() =>
  import("../components/Views/ImportData/ImportData"),
);

const FileImports = lazy(() =>
  import("../components/Views/FileImport/FileImports"),
);

const Data = lazy(() => import("../components/Views/Data/Data"));

const Company = lazy(() => import("../components/Views/Company/Company"));
const DisputeStatus = lazy(() =>
  import("../components/Views/DisputeStatus/DisputeStatus"),
);
const InvoiceStatus = lazy(() =>
  import("../components/Views/InvoiceStatus/InvoiceStatus"),
);
const Workflows = lazy(() => import("../components/Views/Workflow/Workflows"));
const Workflow = lazy(() => import("../components/Views/Workflow/Workflow"));
const Subsidiaries = lazy(() =>
  import("../components/Views/Subsidiary/Subsidiaries"),
);
const ErrorPage = lazy(() => import("../error-page"));
const MessageTemplate = lazy(() =>
  import("../components/Views/MessagesTemplates/MessageTemplate"),
);
const MessageTemplates = lazy(() =>
  import("../components/Views/MessagesTemplates/MessageTemplates"),
);

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "portal/:id",
      element: <Portal />,
      errorElement: (
        <Suspense fallback={<span>Loading...</span>}>
          <ErrorPage />
        </Suspense>
      ),
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      errorElement: (
        <Suspense fallback={<span>Loading...</span>}>
          <ErrorPage />
        </Suspense>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Dashboard />
            </Suspense>
          ),
        },
        {
          path: "/dashboard",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Dashboard />
            </Suspense>
          ),
        },
        {
          path: "/reminders",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <RemindersContainer />
            </Suspense>
          ),
        },
        {
          path: "accounts",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <Accounts />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <AccountContainer />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/disputes",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <DisputesContainer />
            </Suspense>
          ),
        },
        {
          path: "/actions",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <ActionsContainer />
            </Suspense>
          ),
        },
        {
          path: "/campaigns",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <Campaigns />
                </Suspense>
              ),
            },
            {
              path: ":campaignId",
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <CampaignContainer />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/imports",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <ImportData />
                </Suspense>
              ),
            },
            {
              path: ":templateId",
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <FileImports />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/imports/:templateId/import/:importId/logs",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <FileImportLogs />
            </Suspense>
          ),
        },
        {
          path: "message_templates",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <MessageTemplates />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <MessageTemplate />
                </Suspense>
              ),
            },
            {
              path: "new",
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <MessageTemplate />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/company",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Company />
            </Suspense>
          ),
        },
        {
          path: "/data",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Data />
            </Suspense>
          ),
        },
        {
          path: "/subsidiaries",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Subsidiaries />
            </Suspense>
          ),
        },
        {
          path: "/disputes_status",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <DisputeStatus />
            </Suspense>
          ),
        },
        {
          path: "/invoices_status",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <InvoiceStatus />
            </Suspense>
          ),
        },
        {
          path: "/workflows",
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <Workflows />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<span>Loading...</span>}>
                  <Workflow />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/logout",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Logout />
            </Suspense>
          ),
        },
        {
          path: "/signin",
          element: (
            <Suspense fallback={<span>Loading...</span>}>
              <Dashboard />
            </Suspense>
          ),
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/signin",
      element: (
        <Suspense fallback={<span>Loading...</span>}>
          <Signin />
        </Suspense>
      ),
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
