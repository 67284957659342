import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./i18n";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

// import reportWebVitals from './reportWebVitals';

Bugsnag.start({
  apiKey: "62bdfdfb7fb4826bde74d01130a9488c",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "62bdfdfb7fb4826bde74d01130a9488c" });

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
