import { useRef } from "react";
import { KSecondaryButton } from "./StyledComponents";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

const FileUploader = ({ handleFile, onlyImage = false }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (e) => {
    const fileUploaded = e.target.files[0];
    if (!fileUploaded?.type.match(imageMimeType) && onlyImage) {
      alert("Image mime type is not valid");
      return;
    }

    handleFile(e);
  };

  return (
    <div>
      <KSecondaryButton size="sm" onClick={handleClick}>
        Upload
      </KSecondaryButton>
      <input
        type="file"
        ref={hiddenFileInput}
        accept={onlyImage && ".png, .jpg, .jpeg"}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileUploader;
