import { Nav } from "react-bootstrap";
import {
  KButton,
  KCard,
  KNavLink,
  KTable,
} from "../../CustomTags/StyledComponents";
import InvoicesListColumns from "./InvoicesListColumns";
import InvoicesListRow from "./InvoicesListRow";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import formatAmount from "../../../js-functions/formatAmount";

const InvoicesList = ({ invoices, getCheckoutUrl, stripeEnabled }) => {
  const { t } = useTranslation();
  const [displayedInvoices, setDisplayedInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (invoices) {
      setDisplayedInvoices(
        invoices.filter((invoice) => parseFloat(invoice.balance) !== 0),
      );
    }
  }, [invoices]);

  useEffect(() => {
    // calculate total amount of selected invoices
    let total = 0;
    selectedInvoices.forEach((invoice) => {
      total +=
        parseFloat(invoice.balance) + parseFloat(invoice.processing_fees);
    });
    setTotalAmount(total);
  }, [selectedInvoices]);

  // selectedInvoices as an array of ids
  const selectedInvoicesIds = selectedInvoices.map((inv) => inv.id);

  const selectInvoice = (invoice) => {
    const isSelected =
      selectedInvoices.filter((inv) => inv.id === invoice.id).length > 0;

    if (isSelected) {
      // remove if already selected
      setSelectedInvoices(
        selectedInvoices.filter((inv) => inv.id !== invoice.id),
      );
    } else {
      // add if not yet selected
      setSelectedInvoices([...selectedInvoices, invoice]);
    }
  };

  return (
    <>
      <Nav
        variant="pills"
        defaultActiveKey={"unpaid"}
        className="mb-4"
        onSelect={(selectedKey) => {
          if (selectedKey === "paid") {
            setDisplayedInvoices(
              invoices.filter((invoice) => parseFloat(invoice.balance) === 0),
            );
          } else {
            setDisplayedInvoices(
              invoices.filter((invoice) => parseFloat(invoice.balance) !== 0),
            );
          }
        }}
      >
        <Nav.Item className="me-1">
          <KNavLink eventKey={"unpaid"}>
            <Trans i18nKey="Unpaid" count={2}>
              Unpaid
            </Trans>
          </KNavLink>
        </Nav.Item>
        <Nav.Item>
          <KNavLink eventKey={"paid"}>
            <Trans i18nKey="Paid" count={2}>
              Paid
            </Trans>
          </KNavLink>
        </Nav.Item>
        <Nav.Item className="ms-auto me-5 mt-4">
          {totalAmount > 0 && (
            <KButton onClick={() => getCheckoutUrl(selectedInvoicesIds)}>
              {t("Pay")} {formatAmount(totalAmount)}
            </KButton>
          )}
        </Nav.Item>
      </Nav>
      <KCard>
        <KTable hover className="mt-2">
          <InvoicesListColumns />
          <tbody>
            {displayedInvoices?.map((invoice, index) => {
              return (
                <InvoicesListRow
                  key={index}
                  invoice={invoice}
                  selectInvoice={selectInvoice}
                  selectedInvoices={selectedInvoices}
                  getCheckoutUrl={getCheckoutUrl}
                  stripeEnabled={stripeEnabled}
                />
              );
            })}
          </tbody>
        </KTable>
      </KCard>
    </>
  );
};

export default InvoicesList;
