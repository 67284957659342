import FileUploader from "../CustomTags/FileUploader";
import ImageReader from "../CustomTags/ImageReader";

const UserPicture = ({ formData, setFormData, pictureUrl }) => {
  const handleFile = (e) => {
    const fileUploaded = e.target.files[0];
    setFormData({ ...formData, picture: fileUploaded });
  };

  return (
    <>
      <ImageReader
        fileUrl={pictureUrl}
        localFile={formData.picture}
        style={{ height: "150px", width: "150px" }}
        imageType={"rounded-circle"}
      />
      <div style={{ marginTop: "20px" }}>
        <FileUploader handleFile={(f) => handleFile(f)} />
      </div>
    </>
  );
};
export default UserPicture;
